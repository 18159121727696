module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <a class="info default-icon">User: \n    <span class="toggle"> \n      '+
((__t=(_.escape(username)))==null?'':__t)+
'\n    </span>\n    <i id="userLogoutIcon" class="fa fa-power-off" aria-hidden="true"></i>\n  </a>\n\n  <ul id="userInfo" class="subBarDropdown">\n    <li class="dropdown-header">\n      <div style="height: 1px; background: rgb(119, 203, 153)"></div>\n      <img class="user-img" src="'+
((__t=(img))==null?'':__t)+
'"/>\n        <p>';
 if (name) {
__p+=''+
((__t=(_.escape(username)))==null?'':__t)+
'<small> '+
((__t=(_.escape(name)))==null?'':__t)+
' </small>\n        ';
 } else {
__p+='\n          '+
((__t=(_.escape(username)))==null?'':__t)+
'\n        ';
 } 
__p+='\n        </p>\n    </li>\n    <li class="dropdown-footer">\n      <button id="userLogout" class="button-primary btn-small pull-right">Logout</button>\n    </li>\n  </ul>\n\n<!--\n<ul class="navlist" id="userBarUl">\n\n  <li class="dropdown user-menu userImg">\n    <a href="#" class="tab userImg" id="user" >\n      <img class="user-menu-img" src="'+
((__t=(img))==null?'':__t)+
'" id="userimage" /> <b class="caret"></b>\n    </a>\n    <ul class="user-dropdown-menu" id="user_dropdown">\n      <li class="dropdown-header" style="text-transform: none">\n        ';

        if (name) {
__p+='\n        '+
((__t=(_.escape(name)))==null?'':__t)+
' ('+
((__t=(_.escape(username)))==null?'':__t)+
')\n        ';
 } else {
__p+='\n        '+
((__t=(_.escape(username)))==null?'':__t)+
'\n        ';
 } 
__p+='\n      <li class="dropdown-item">\n        <a id="userProfile" class="tab" href="#user">User Profile</a>\n      </li>\n      <li class="dropdown-item">\n        <a id="userLogout" class="tab">Logout</a>\n      </li>\n    </ul>\n  </li>\n\n</ul>\n\n--> \n';
}
return __p;
};
