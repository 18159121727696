module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="paddingBox">\n    <div class="foxxDesc">\n      <p><span class="foxxMount">'+
((__t=(model.get("mount")))==null?'':__t)+
'</span></p>\n      <p><span class="foxxName">'+
((__t=(model.get("name")))==null?'':__t)+
'</span></p>\n      <p><span class="foxxName">'+
((__t=(model.get("version")))==null?'':__t)+
'</span></p>\n      <!--<p><span class="foxxCategory">'+
((__t=(model.get("category")))==null?'':__t)+
'</span></p>-->\n    </div>\n    <!-- <div class="foxxActions"><button class="installFoxx button-success" appId="'+
((__t=(model.name ))==null?'':__t)+
'" appVersion="'+
((__t=(model.latestVersion))==null?'':__t)+
'" >Interface</button></div>-->\n    <div class="borderBox"></div>\n    <img src="'+
((__t=( model.thumbnailUrl ))==null?'':__t)+
'" height="50" width="50" alt="Icon for Service" class="icon">\n    ';
 if(model.isDevelopment()) { 
__p+='\n      <div class="tileBadge">\n        <span>\n          <div class="corneredBadge development">\n            Development\n          </div>\n        </span>\n      </div>\n    ';
 } else { 
__p+='\n      <div class="tileBadge">\n        <span>\n          <div class="corneredBadge production">\n            Production\n          </div>\n        </span>\n      </div>\n    ';
 } 
__p+='\n  </div>\n';
}
return __p;
};
