module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

  function createSuite(suite) {
    
__p+='\n    <dt class="tests-result-spec-suite-title">\n      '+
((__t=( suite.title ))==null?'':__t)+
'\n    </dt>\n    <dd>\n      ';
 if (suite.tests.length) { 
__p+='\n        <ul class="tests-result-spec-tests">\n          ';
 _.each(suite.tests, createTest) 
__p+='\n        </ul>\n      ';
 } 
__p+='\n      ';
 if (suite.suites.length) { 
__p+='\n        <dl class="tests-result-spec-suites">\n          ';
 _.each(suite.suites, createSuite) 
__p+='\n        </dl>\n      ';
 } 
__p+='\n    </dd>\n    ';

  }
  function createTest(test) {
    var slow = test.duration > 75;
    
__p+='\n    <li class="tests-result-spec-test '+
((__t=( test.result ))==null?'':__t)+
''+
((__t=( slow ? ' slow': '' ))==null?'':__t)+
'">\n      <span class="tests-result-spec-test-title">\n        ';
 switch (test.result) {
          case 'pass': 
__p+='\n          <span class="fa fa-check"></span>\n          ';
 break;
          case 'fail': 
__p+='\n          <span class="fa fa-times"></span>\n          ';
 break;
          case 'pending': 
__p+='\n          <span class="fa fa-circle"></span>\n        ';
 } 
__p+='\n        '+
((__t=( test.title ))==null?'':__t)+
'\n        '+
((__t=( slow ? '(' + test.duration + 'ms)' : '' ))==null?'':__t)+
'\n      </span>\n      ';
 if (!_.isEmpty(test.err)) { 
__p+='\n        <pre class="stack">'+
((__t=( test.err.stack ))==null?'':__t)+
'</pre>\n      ';
 } 
__p+='\n    </li>\n  ';

  } 
__p+='\n  <div class="tests-result">\n    ';
 if (info.stack) { 
__p+='\n      <div class="tests-result-stats fail">\n        Test runner failed with an error.\n      </div>\n      <pre class="stack">'+
((__t=( info.stack ))==null?'':__t)+
'</pre>\n    ';
 } else { 
__p+='\n      <div class="tests-result-stats">\n        Completed <b>'+
((__t=( info.stats.tests ))==null?'':__t)+
'</b> tests in <b>'+
((__t=( info.stats.duration ))==null?'':__t)+
'ms</b>\n        (<span class="pass" title="passes">'+
((__t=( info.stats.passes
        ))==null?'':__t)+
'</span>/<span class="fail" title="failures">'+
((__t=( info.stats.failures
        ))==null?'':__t)+
'</span>/<span class="pending" title="pending">'+
((__t=( info.stats.pending
        ))==null?'':__t)+
'</span>)\n      </div>\n      <div class="tests-result-spec">\n        ';
 if (info.tests.length) { 
__p+='\n          <ul class="tests-result-spec-tests">\n            ';
 _.each(info.tests, createTest) 
__p+='\n          </ul>\n        ';
 } 
__p+='\n        ';
 if (info.suites.length) { 
__p+='\n          <dl class="tests-result-spec-suites">\n            ';
 _.each(info.suites, createSuite) 
__p+='\n          </dl>\n        ';
 } 
__p+='\n        ';
 if (!info.tests.length && !info.suites.length) { 
__p+='\n          <div class="tests-result-spec-empty">No tests found.</div>\n        ';
 } 
__p+='\n      </div>\n    ';
 } 
__p+='\n  </div>\n';
}
return __p;
};
