module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="install-foxx" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display:none">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n    <a class="arangoHeader">Install Service</a>\n  </div>\n  <div class="modal-body">\n    <table>\n      <tr>\n        <th class="collectionTh">Name:</th>\n        <th class="collectionTh"><strong>'+
((__t=(attributes.name ))==null?'':__t)+
'</strong></th>\n      </tr>\n      <tr>\n        <th class="collectionTh">&nbsp;</th>\n        <th class="collectionTh">&nbsp;</th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Mount:</th>\n        <th class="collectionTh">\n          <input type="text" id="mount-point" name="mountpoint" value="/'+
((__t=(attributes.name))==null?'':__t)+
'" placeholder="mount-path" />\n        </th>\n        <th><a class="modalTooltips" title="The path where the app can be reached."><i class="arangoicon icon_arangodb_info"></i></a></th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Version:</th>\n        <th class="collectionTh">\n          '+
((__t=(attributes.version ))==null?'':__t)+
'\n        <th>\n      </tr>\n      <tr>\n        <th class="collectionTh">System:</th>\n        <th class="collectionTh">\n          '+
((__t=(attributes.isSystem ? "Yes" : "No" ))==null?'':__t)+
'\n        <th>\n      </tr>\n    </table>\n  </div>\n  <div id="colFooter" class="modal-footer">\n    <button class="button-success pull-right installFoxx">Install</button>    \n    <button id="cancel" class="button-danger pull-right">Cancel</button>\n  </div>\n</div>\n';
}
return __p;
};
