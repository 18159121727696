module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  ';
 var genClass = 'pure-u-1-3'; 
__p+='\n  ';
 var genClass2 = 'pure-u-2-3'; 
__p+='\n\n  ';
 var formatName = function(name) { 
__p+='\n    ';
 return name.charAt(0).toUpperCase() + string.slice(1);
__p+='\n  ';
 }; 
__p+='\n\n  <div id="graphSettingsView" class="innerContent">\n    \n    <div class="pure-g" style="margin-top: -13px">\n      <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\n    \n        <div class="pure-g pure-table pure-table-body">\n\n          ';
 _.each(general, function(val, key) { 
__p+='\n            ';
 if (val.type === 'divider') { 
__p+='\n              <div class="pure-u-1-1 left heading">'+
((__t=(val.name))==null?'':__t)+
'</div>\n            ';
 } else { 
__p+='\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">\n              '+
((__t=(val.name))==null?'':__t)+
'\n            </div>\n            <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">\n\n              ';
 if (val.type === 'select') { 
__p+='\n                  <select id="g_'+
((__t=(key))==null?'':__t)+
'">\n                    ';
 _.each(val, function(option, optKey) { 
__p+='\n                      ';
 if (option.name) { 
__p+='\n                        <option value="'+
((__t=(option.val))==null?'':__t)+
'"> '+
((__t=(option.name))==null?'':__t)+
' </option>\n                      ';
 } 
__p+='\n                    ';
 }); 
__p+='\n                  </select>\n              ';
 } 
__p+='\n\n              ';
 if (val.type === 'string') { 
__p+='\n                <input id="g_'+
((__t=(key))==null?'':__t)+
'" type="text" placeholder="attribute"></input>\n              ';
 } 
__p+='\n\n              ';
 if (val.type === 'number') { 
__p+='\n                <input id="g_'+
((__t=(key))==null?'':__t)+
'" type="text" id="'+
((__t=(val ))==null?'':__t)+
'" value="'+
((__t=(val.value ))==null?'':__t)+
'" placeholder=""></input>\n              ';
 } 
__p+='\n\n              ';
 if (val.type === 'range') { 
__p+='\n                <label id="g_'+
((__t=(key))==null?'':__t)+
'_label" class="rangeLabel">1</label>\n                <input id="g_'+
((__t=(key))==null?'':__t)+
'" type=\'range\' min="0" max="9" val="1"/>\n              ';
 } 
__p+='\n\n              ';
 if (val.type === 'color') { 
__p+='\n                <input id="g_'+
((__t=(key))==null?'':__t)+
'" type=\'color\' name=\'color\' value="'+
((__t=(VALUE))==null?'':__t)+
'"/>\n              ';
 } 
__p+='\n\n              <i title="'+
((__t=(val.desc))==null?'':__t)+
'" class="fa fa-info-circle gv-tooltips" aria-hidden="true"></i>\n              </div>\n            ';
 } 
__p+='\n\n          ';
 }); 
__p+='\n        </div>\n\n      </div>\n\n      <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\n    \n        <div class="pure-g pure-table pure-table-body">\n          ';
 _.each(specific, function(val, key) { 
__p+='\n\n            ';
 if (val.hide !== 'true') { 
__p+='\n              ';
 if (val.type === 'divider') { 
__p+='\n                <div class="pure-u-1-1 left heading">'+
((__t=(val.name))==null?'':__t)+
'</div>\n              ';
 } else { 
__p+='\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">\n                  '+
((__t=(val.name))==null?'':__t)+
'\n                </div>\n\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">\n\n                  ';
 var VALUE; 
__p+='\n                  ';
 if (val.value) { 
__p+='\n                    ';
 VALUE = val.value 
__p+='\n                  ';
 } else { 
__p+='\n                    ';
 VALUE = val.default 
__p+='\n                  ';
 } 
__p+='\n\n                  ';
 if (val.type === 'string') { 
__p+='\n                    <input id="g_'+
((__t=(key))==null?'':__t)+
'" type="text" placeholder="attribute"></input>\n                  ';
 } 
__p+='\n\n                  ';
 if (val.type === 'number') { 
__p+='\n                    <input id="g_'+
((__t=(key))==null?'':__t)+
'" type="text" placeholder="number"></input>\n                  ';
 } 
__p+='\n\n                  ';
 if (val.type === 'color') { 
__p+='\n                    <input id="g_'+
((__t=(key))==null?'':__t)+
'" type=\'color\' name=\'color\' value="'+
((__t=(VALUE))==null?'':__t)+
'"/>\n                  ';
 } 
__p+='\n\n                  ';
 if (val.type === 'range') { 
__p+='\n                    <label id="g_'+
((__t=(key))==null?'':__t)+
'_label" class="rangeLabel">1</label>\n                    <input id="g_'+
((__t=(key))==null?'':__t)+
'" type=\'range\' min="0" max="9" val="1"/>\n                  ';
 } 
__p+='\n\n                  ';
 if (val.type === 'select') { 
__p+='\n                    <select id="g_'+
((__t=(key))==null?'':__t)+
'">\n                      ';
 _.each(val, function(option, optKey) { 
__p+='\n                        ';
 if (option.name) { 
__p+='\n                          <option value="'+
((__t=(option.val))==null?'':__t)+
'"> '+
((__t=(option.name))==null?'':__t)+
' </option>\n                        ';
 } 
__p+='\n                      ';
 }); 
__p+='\n                    </select>\n                  ';
 } 
__p+='\n                  <i title="'+
((__t=(val.desc))==null?'':__t)+
'" class="fa fa-info-circle gv-tooltips" aria-hidden="true"></i>\n                </div>\n              ';
 } 
__p+='\n            ';
 } 
__p+='\n          ';
 }); 
__p+='\n        </div>\n\n        <div class="pure-u-1-1">\n          <button id="saveGraphSettings" style="margin-top: 20px; margin-right: 10px;" class="button-success pull-right">Save</button>\n          <button id="restoreGraphSettings" style="margin-top: 20px;" class="button-success pull-right">Restore defaults</button>\n        </div>\n      </div>\n  </div>\n\n';
}
return __p;
};
