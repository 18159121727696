module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="application-detail-view">\n\n    <div class="headerBar" style="width: 100%">\n      <ul class="subMenuEntries subViewNavbar pull-left">\n      </ul>\n    </div>\n\n    <section class="info" id="information" style="width: 100%;">\n      <div class="pure-g">\n\n        <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-3-4 foxx-left">\n          <div class="foxx-icon-container pull-left">\n            <img src="'+
((__t=( image ))==null?'':__t)+
'" alt="Icon for Service" class="icon" />\n          </div>\n          <div class="pull-left" style="margin-left: 15px">\n            <h3>\n              '+
((__t=( app.attributes.name ))==null?'':__t)+
'\n            </h3>\n            <p class="description">'+
((__t=( app.attributes.description ))==null?'':__t)+
'</p>\n          </div>\n           <div class="categoryBar">\n             <h4>Readme</h4>\n           </div>\n          <div id="readme" class="readme">\n          </div>\n        </div>\n\n        <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-1-4 foxx-right">\n          <div class="foxx-label-container">\n            <p>\n              <span>Author: </span><span>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span>Version: </span><span class="version">'+
((__t=( app.attributes.latestVersion ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span>GitHub: </span><span><a href="https://www.github.com/'+
((__t=( app.attributes.location ))==null?'':__t)+
'">'+
((__t=( app.attributes.location ))==null?'':__t)+
'</a></span>\n            </p>\n            <p>\n              ';
 if (app.attributes.license) { 
__p+='\n                <span>License: </span><span>'+
((__t=( app.attributes.license ))==null?'':__t)+
'</span>\n              ';
 } 
__p+='\n            </p>\n            <p>\n              <span>Categories: </span><span>'+
((__t=( app.attributes.categories ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span></span>\n            </p>\n          </div>\n          <div class="header_right">\n            <input id="installService" type="button" class="button-success" value="Install Service" />\n          </div>\n        </div>\n\n      </div>\n    </section>\n\n  </div>\n';
}
return __p;
};
