module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <tr class="foxx-store-row">\n    <td class="foxx-store-main">\n      <div class="foxx-name">'+
((__t=(name))==null?'':__t)+
''+
((__t=(legacy?' (legacy)':''))==null?'':__t)+
'</div>\n      <div class="foxx-author">'+
((__t=(author))==null?'':__t)+
'</div>\n      <div class="foxx-description">'+
((__t=(description))==null?'':__t)+
'</div>\n    </td>\n    <td class="foxx-store-version">\n      <div class="foxx-version">'+
((__t=(latestVersion))==null?'':__t)+
'</div>\n    </td>\n    <td class="foxx-store-install">\n      <button class="button-success install-app" appId="'+
((__t=(name ))==null?'':__t)+
'" appVersion="'+
((__t=(latestVersion ))==null?'':__t)+
'">Install</button>\n    </td>\n  </tr>\n';
}
return __p;
};
