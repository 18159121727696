/*!
 * numeral.js
 * version : 1.5.3
 * author : Adam Draper
 * license : MIT
 * http://adamwdraper.github.com/Numeral-js/
 */
(function () {
  function a(a) {
    this._value = a;
  }

  function b(a, b, c, d) {
    var e,
        f,
        g = Math.pow(10, b);
    return f = (c(a * g) / g).toFixed(b), d && (e = new RegExp("0{1," + d + "}$"), f = f.replace(e, "")), f;
  }

  function c(a, b, c) {
    var d;
    return d = b.indexOf("$") > -1 ? e(a, b, c) : b.indexOf("%") > -1 ? f(a, b, c) : b.indexOf(":") > -1 ? g(a, b) : i(a._value, b, c);
  }

  function d(a, b) {
    var c,
        d,
        e,
        f,
        g,
        i = b,
        j = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        k = !1;
    if (b.indexOf(":") > -1) a._value = h(b);else if (b === q) a._value = 0;else {
      for ("." !== o[p].delimiters.decimal && (b = b.replace(/\./g, "").replace(o[p].delimiters.decimal, ".")), c = new RegExp("[^a-zA-Z]" + o[p].abbreviations.thousand + "(?:\\)|(\\" + o[p].currency.symbol + ")?(?:\\))?)?$"), d = new RegExp("[^a-zA-Z]" + o[p].abbreviations.million + "(?:\\)|(\\" + o[p].currency.symbol + ")?(?:\\))?)?$"), e = new RegExp("[^a-zA-Z]" + o[p].abbreviations.billion + "(?:\\)|(\\" + o[p].currency.symbol + ")?(?:\\))?)?$"), f = new RegExp("[^a-zA-Z]" + o[p].abbreviations.trillion + "(?:\\)|(\\" + o[p].currency.symbol + ")?(?:\\))?)?$"), g = 0; g <= j.length && !(k = b.indexOf(j[g]) > -1 ? Math.pow(1024, g + 1) : !1); g++) {
        ;
      }

      a._value = (k ? k : 1) * (i.match(c) ? Math.pow(10, 3) : 1) * (i.match(d) ? Math.pow(10, 6) : 1) * (i.match(e) ? Math.pow(10, 9) : 1) * (i.match(f) ? Math.pow(10, 12) : 1) * (b.indexOf("%") > -1 ? .01 : 1) * ((b.split("-").length + Math.min(b.split("(").length - 1, b.split(")").length - 1)) % 2 ? 1 : -1) * Number(b.replace(/[^0-9\.]+/g, "")), a._value = k ? Math.ceil(a._value) : a._value;
    }
    return a._value;
  }

  function e(a, b, c) {
    var d,
        e,
        f = b.indexOf("$"),
        g = b.indexOf("("),
        h = b.indexOf("-"),
        j = "";
    return b.indexOf(" $") > -1 ? (j = " ", b = b.replace(" $", "")) : b.indexOf("$ ") > -1 ? (j = " ", b = b.replace("$ ", "")) : b = b.replace("$", ""), e = i(a._value, b, c), 1 >= f ? e.indexOf("(") > -1 || e.indexOf("-") > -1 ? (e = e.split(""), d = 1, (g > f || h > f) && (d = 0), e.splice(d, 0, o[p].currency.symbol + j), e = e.join("")) : e = o[p].currency.symbol + j + e : e.indexOf(")") > -1 ? (e = e.split(""), e.splice(-1, 0, j + o[p].currency.symbol), e = e.join("")) : e = e + j + o[p].currency.symbol, e;
  }

  function f(a, b, c) {
    var d,
        e = "",
        f = 100 * a._value;
    return b.indexOf(" %") > -1 ? (e = " ", b = b.replace(" %", "")) : b = b.replace("%", ""), d = i(f, b, c), d.indexOf(")") > -1 ? (d = d.split(""), d.splice(-1, 0, e + "%"), d = d.join("")) : d = d + e + "%", d;
  }

  function g(a) {
    var b = Math.floor(a._value / 60 / 60),
        c = Math.floor((a._value - 60 * b * 60) / 60),
        d = Math.round(a._value - 60 * b * 60 - 60 * c);
    return b + ":" + (10 > c ? "0" + c : c) + ":" + (10 > d ? "0" + d : d);
  }

  function h(a) {
    var b = a.split(":"),
        c = 0;
    return 3 === b.length ? (c += 60 * Number(b[0]) * 60, c += 60 * Number(b[1]), c += Number(b[2])) : 2 === b.length && (c += 60 * Number(b[0]), c += Number(b[1])), Number(c);
  }

  function i(a, c, d) {
    var e,
        f,
        g,
        h,
        i,
        j,
        k = !1,
        l = !1,
        m = !1,
        n = "",
        r = !1,
        s = !1,
        t = !1,
        u = !1,
        v = !1,
        w = "",
        x = "",
        y = Math.abs(a),
        z = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        A = "",
        B = !1;
    if (0 === a && null !== q) return q;
    if (c.indexOf("(") > -1 ? (k = !0, c = c.slice(1, -1)) : c.indexOf("+") > -1 && (l = !0, c = c.replace(/\+/g, "")), c.indexOf("a") > -1 && (r = c.indexOf("aK") >= 0, s = c.indexOf("aM") >= 0, t = c.indexOf("aB") >= 0, u = c.indexOf("aT") >= 0, v = r || s || t || u, c.indexOf(" a") > -1 ? (n = " ", c = c.replace(" a", "")) : c = c.replace("a", ""), y >= Math.pow(10, 12) && !v || u ? (n += o[p].abbreviations.trillion, a /= Math.pow(10, 12)) : y < Math.pow(10, 12) && y >= Math.pow(10, 9) && !v || t ? (n += o[p].abbreviations.billion, a /= Math.pow(10, 9)) : y < Math.pow(10, 9) && y >= Math.pow(10, 6) && !v || s ? (n += o[p].abbreviations.million, a /= Math.pow(10, 6)) : (y < Math.pow(10, 6) && y >= Math.pow(10, 3) && !v || r) && (n += o[p].abbreviations.thousand, a /= Math.pow(10, 3))), c.indexOf("b") > -1) for (c.indexOf(" b") > -1 ? (w = " ", c = c.replace(" b", "")) : c = c.replace("b", ""), g = 0; g <= z.length; g++) {
      if (e = Math.pow(1024, g), f = Math.pow(1024, g + 1), a >= e && f > a) {
        w += z[g], e > 0 && (a /= e);
        break;
      }
    }
    return c.indexOf("o") > -1 && (c.indexOf(" o") > -1 ? (x = " ", c = c.replace(" o", "")) : c = c.replace("o", ""), x += o[p].ordinal(a)), c.indexOf("[.]") > -1 && (m = !0, c = c.replace("[.]", ".")), h = a.toString().split(".")[0], i = c.split(".")[1], j = c.indexOf(","), i ? (i.indexOf("[") > -1 ? (i = i.replace("]", ""), i = i.split("["), A = b(a, i[0].length + i[1].length, d, i[1].length)) : A = b(a, i.length, d), h = A.split(".")[0], A = A.split(".")[1].length ? o[p].delimiters.decimal + A.split(".")[1] : "", m && 0 === Number(A.slice(1)) && (A = "")) : h = b(a, null, d), h.indexOf("-") > -1 && (h = h.slice(1), B = !0), j > -1 && (h = h.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + o[p].delimiters.thousands)), 0 === c.indexOf(".") && (h = ""), (k && B ? "(" : "") + (!k && B ? "-" : "") + (!B && l ? "+" : "") + h + A + (x ? x : "") + (n ? n : "") + (w ? w : "") + (k && B ? ")" : "");
  }

  function j(a, b) {
    o[a] = b;
  }

  function k(a) {
    var b = a.toString().split(".");
    return b.length < 2 ? 1 : Math.pow(10, b[1].length);
  }

  function l() {
    var a = Array.prototype.slice.call(arguments);
    return a.reduce(function (a, b) {
      var c = k(a),
          d = k(b);
      return c > d ? c : d;
    }, -1 / 0);
  }

  var _m,
      n = "1.5.3",
      o = {},
      p = "en",
      q = null,
      r = "0,0",
      s = "undefined" != typeof module && module.exports;

  _m = function m(b) {
    return _m.isNumeral(b) ? b = b.value() : 0 === b || "undefined" == typeof b ? b = 0 : Number(b) || (b = _m.fn.unformat(b)), new a(Number(b));
  }, _m.version = n, _m.isNumeral = function (b) {
    return b instanceof a;
  }, _m.language = function (a, b) {
    if (!a) return p;

    if (a && !b) {
      if (!o[a]) throw new Error("Unknown language : " + a);
      p = a;
    }

    return (b || !o[a]) && j(a, b), _m;
  }, _m.languageData = function (a) {
    if (!a) return o[p];
    if (!o[a]) throw new Error("Unknown language : " + a);
    return o[a];
  }, _m.language("en", {
    delimiters: {
      thousands: ",",
      decimal: "."
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t"
    },
    ordinal: function ordinal(a) {
      var b = a % 10;
      return 1 === ~~(a % 100 / 10) ? "th" : 1 === b ? "st" : 2 === b ? "nd" : 3 === b ? "rd" : "th";
    },
    currency: {
      symbol: "$"
    }
  }), _m.zeroFormat = function (a) {
    q = "string" == typeof a ? a : null;
  }, _m.defaultFormat = function (a) {
    r = "string" == typeof a ? a : "0.0";
  }, "function" != typeof Array.prototype.reduce && (Array.prototype.reduce = function (a, b) {
    "use strict";

    if (null === this || "undefined" == typeof this) throw new TypeError("Array.prototype.reduce called on null or undefined");
    if ("function" != typeof a) throw new TypeError(a + " is not a function");
    var c,
        d,
        e = this.length >>> 0,
        f = !1;

    for (1 < arguments.length && (d = b, f = !0), c = 0; e > c; ++c) {
      this.hasOwnProperty(c) && (f ? d = a(d, this[c], c, this) : (d = this[c], f = !0));
    }

    if (!f) throw new TypeError("Reduce of empty array with no initial value");
    return d;
  }), _m.fn = a.prototype = {
    clone: function clone() {
      return _m(this);
    },
    format: function format(a, b) {
      return c(this, a ? a : r, void 0 !== b ? b : Math.round);
    },
    unformat: function unformat(a) {
      return "[object Number]" === Object.prototype.toString.call(a) ? a : d(this, a ? a : r);
    },
    value: function value() {
      return this._value;
    },
    valueOf: function valueOf() {
      return this._value;
    },
    set: function set(a) {
      return this._value = Number(a), this;
    },
    add: function add(a) {
      function b(a, b) {
        return a + c * b;
      }

      var c = l.call(null, this._value, a);
      return this._value = [this._value, a].reduce(b, 0) / c, this;
    },
    subtract: function subtract(a) {
      function b(a, b) {
        return a - c * b;
      }

      var c = l.call(null, this._value, a);
      return this._value = [a].reduce(b, this._value * c) / c, this;
    },
    multiply: function multiply(a) {
      function b(a, b) {
        var c = l(a, b);
        return a * c * b * c / (c * c);
      }

      return this._value = [this._value, a].reduce(b, 1), this;
    },
    divide: function divide(a) {
      function b(a, b) {
        var c = l(a, b);
        return a * c / (b * c);
      }

      return this._value = [this._value, a].reduce(b), this;
    },
    difference: function difference(a) {
      return Math.abs(_m(this._value).subtract(a).value());
    }
  }, s && (module.exports = _m), "undefined" == typeof ender && (this.numeral = _m), "function" == typeof define && define.amd && define([], function () {
    return _m;
  });
}).call(this);