/* global window, Backbone, $, arangoHelper */
(function () {
  'use strict';

  window.Graph = Backbone.Model.extend({
    idAttribute: '_key',
    urlRoot: arangoHelper.databaseUrl('/_api/gharial'),
    isNew: function isNew() {
      return !this.get('_id');
    },
    parse: function parse(raw) {
      return raw.graph || raw;
    },
    addEdgeDefinition: function addEdgeDefinition(edgeDefinition) {
      $.ajax({
        async: false,
        type: 'POST',
        url: this.urlRoot + '/' + this.get('_key') + '/edge',
        data: JSON.stringify(edgeDefinition),
        error: function error(err) {
          arangoHelper.arangoError(err.responseJSON.errorMessage);
        }
      });
    },
    deleteEdgeDefinition: function deleteEdgeDefinition(edgeCollection) {
      $.ajax({
        async: false,
        type: 'DELETE',
        url: this.urlRoot + '/' + this.get('_key') + '/edge/' + edgeCollection,
        error: function error(err) {
          arangoHelper.arangoError(err.responseJSON.errorMessage);
        }
      });
    },
    modifyEdgeDefinition: function modifyEdgeDefinition(edgeDefinition) {
      $.ajax({
        async: false,
        type: 'PUT',
        url: this.urlRoot + '/' + this.get('_key') + '/edge/' + edgeDefinition.collection,
        data: JSON.stringify(edgeDefinition),
        error: function error(err) {
          arangoHelper.arangoError(err.responseJSON.errorMessage);
        }
      });
    },
    addVertexCollection: function addVertexCollection(vertexCollectionName) {
      $.ajax({
        async: false,
        type: 'POST',
        url: this.urlRoot + '/' + this.get('_key') + '/vertex',
        data: JSON.stringify({
          collection: vertexCollectionName
        }),
        error: function error(err) {
          arangoHelper.arangoError(err.responseJSON.errorMessage);
        }
      });
    },
    deleteVertexCollection: function deleteVertexCollection(vertexCollectionName) {
      $.ajax({
        async: false,
        type: 'DELETE',
        url: this.urlRoot + '/' + this.get('_key') + '/vertex/' + vertexCollectionName,
        error: function error(err) {
          arangoHelper.arangoError(err.responseJSON.errorMessage);
        }
      });
    },
    defaults: {
      name: '',
      edgeDefinitions: [],
      orphanCollections: []
    }
  });
})();