module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="headerBar">\n    <div class="search-field">\n      <input type="text" value="'+
((__t=(searchString))==null?'':__t)+
'" id="userManagementSearchInput" class="search-input" placeholder="Search..."/>\n      <!-- <img id="userManagementSearchSubmit" class="search-submit-icon"> -->\n      <i id="userManagementSearchSubmit" class="fa fa-search"></i>\n    </div>\n    <div class="headerButtonBar">\n      <ul class="headerButtonList">\n        <li class="enabled">\n        <a id="userManagementToggle" class="headerButton">\n          <span class="icon_arangodb_settings2" title="Settings"></span>\n        </a>\n        </li>\n      </ul>\n    </div>\n  </div>\n\n\n<div id="userManagementDropdown2" class="headerDropdown">\n\n  <div id="userManagementDropdown" class="dropdownInner">\n    <ul>\n      <li class="nav-header">Sorting</li>\n\n      <!--<li><a href="#">\n        <label class="checkbox checkboxLabel">\n          <input class="css-checkbox" type="checkbox" id="sortName">\n          <label class="css-label css-label-round"></label>Sort by username\n        </label>\n      </a></li>\n\n      <li><a href="#">\n        <label class="checkbox checkboxLabel">\n          <input class="css-checkbox" type="checkbox" id="sortType">\n          <label class="css-label css-label-round"></label>Sort by status\n        </label>\n      </a></li>-->\n\n      <li><a href="#">\n        <label class="checkbox checkboxLabel">\n          <input class="css-checkbox" type="checkbox" id="userSortDesc">\n          <i class="fa"></i>\n          Sort descending\n        </label>\n      </a></li>\n    </ul>\n  </div>\n\n</div>\n\n\n  <div class="contentDiv" id="arangoCollectionsContainer">\n    <div id="userManagementThumbnailsIn" class="tileList pure-u">\n      <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6">\n        <div class="fullBorderBox">\n          <a id="createUser" class="add">\n            <span id="newUser" class="pull-left add-Icon"><i class="fa fa-plus-circle"></i></span>\n            Add User\n          </a>\n        </div>\n      </div>\n\n\n            ';
 collection.forEach(function(user) {
              var username = user.get("user"),
                extra = user.get("extra"),
                name = extra.name,
                img = extra.img,
                active = user.get("active"),
                avatar = '<img src="';

              if (! img) {
                avatar = '<i id="' + _.escape(username) + '" class="collection-type-icon fa fa-user"></i>';
              } else {
                avatar += 'https://s.gravatar.com/avatar/';
                avatar += img;
                avatar += '?s=50';
                avatar += '" height="50" width="50" alt="" class="icon" id="';
                avatar += _.escape(username);
                avatar += '" />';
              }
              if (! name) {
                name = " ";
              }
              if (username.substring(0, 6) === ':role:') {
                avatar = '<i id="' + _.escape(username) + '" class="collection-type-icon fa fa-group"></i>';
              }
      
__p+='\n\n      <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6">\n        <div class="paddingBox">\n          <div class="borderBox"></div>\n          <div class="iconSet">\n            <!-- <span class="icon_arangodb_settings2 editUser" id="'+
((__t=(_.escape(username) ))==null?'':__t)+
'_edit-user" alt="Edit user" title="Edit user"></span> -->\n          </div>\n          '+
((__t=(avatar ))==null?'':__t)+
'\n          <div class="tileBadge">\n            <span>\n              ';
if (active) {
__p+='\n              <div class="corneredBadge loaded">\n                active\n              </div>\n              ';
} else {
__p+='\n              <div class="corneredBadge unloaded">\n                inactive\n              </div>\n              ';
}
__p+='\n            </span>\n          </div>\n\n          <h5 class="collectionName">'+
((__t=(_.escape(username) ))==null?'':__t)+
' ';
 if (name !== ' ') { 
__p+='('+
((__t=(_.escape(name) ))==null?'':__t)+
')';
}
__p+='</h5>\n        </div>\n      </div>\n      ';
});
__p+='\n     </div>\n  </div>\n';
}
return __p;
};
