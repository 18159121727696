module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  ';
 if (warnings.length > 0) { 
__p+='\n    <div>\n      <ul>\n        ';
 console.log(warnings); _.each(warnings, function(w) { console.log(w);
__p+='\n          <li><b>'+
((__t=(w.code))==null?'':__t)+
'</b>: '+
((__t=(w.message))==null?'':__t)+
'</li>\n        ';
 }); 
__p+='\n      </ul>\n    </div>\n  ';
 } 
__p+='\n';
}
return __p;
};
