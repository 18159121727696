module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="control-group">\n  <label for="'+
((__t=(type ))==null?'':__t)+
'_'+
((__t=(id))==null?'':__t)+
'" class="control-label">Attribute '+
((__t=(id))==null?'':__t)+
'</label>\n  <div class="controls">\n    <input id="'+
((__t=(type ))==null?'':__t)+
'_'+
((__t=(id))==null?'':__t)+
'" type="text" name="'+
((__t=(type ))==null?'':__t)+
'_'+
((__t=(id))==null?'':__t)+
'" placeholder="Attribute" maxlength="75" class="input-xlarge">\n    <button id="remove_'+
((__t=(type ))==null?'':__t)+
'_'+
((__t=(id))==null?'':__t)+
'" class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete" />\n  </div>\n</div>\n';
}
return __p;
};
