module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var subBar = function(title) { 
__p+='\n  <div class="dashboard-sub-bar">\n    <div class="dashboard-sub-bar-title">'+
((__t=( title ))==null?'':__t)+
'</div>\n  </div>\n';
 } 
__p+='\n\n';
 var largeChart = function(name, title, arrows) { 
__p+='\n  <div class="dashboard-large-chart pure-u-1-1 pure-u-sm-1-1 pure-u-md-2-3">\n    <div class="dashboard-large-chart-inner dashboard-chart" id="'+
((__t=( name ))==null?'':__t)+
'Container">\n      <div class="dashboard-legend">\n        <div class="dashboard-legend-inner" id="'+
((__t=( name ))==null?'':__t)+
'Legend"></div>\n      </div>\n      <div id="'+
((__t=( name ))==null?'':__t)+
'" class="dashboard-interior-chart"></div>\n    </div>\n    ';
 subBar(title) 
__p+='\n  </div>\n';
 } 
__p+='\n\n';
 var mediumChart = function(name, title, arrows) { 
__p+='\n  <div class="dashboard-medium-chart-outer pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-2">\n    <div class="dashboard-medium-chart">\n      <div class="dashboard-legend">\n        <div class="dashboard-legend-inner" id="'+
((__t=( name ))==null?'':__t)+
'Legend"></div>\n      </div>\n      <div class="dashboard-medium-chart-inner dashboard-chart" id="'+
((__t=( name ))==null?'':__t)+
'Container">\n        <div id="'+
((__t=( name ))==null?'':__t)+
'" class="dashboard-interior-chart"></div>\n      </div>\n      ';
 subBar(title) 
__p+='\n    </div>\n  </div>\n';
 } 
__p+='\n\n';
 var smallChart = function(name, title, arrows) { 
__p+='\n  <div class="dashboard-small-chart pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-3">\n    <div class="dashboard-small-chart-inner dashboard-chart" id="'+
((__t=( name ))==null?'':__t)+
'Container">\n      <div id="'+
((__t=( name ))==null?'':__t)+
'" class="dashboard-interior-chart">\n        <svg></svg>\n      </div>\n    </div>\n    ';
 subBar(title) 
__p+='\n  </div>\n';
 } 
__p+='\n';
 var tendency = function(title, name, arrows) { 
__p+='\n\n<div class="dashboard-tendency-container pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-3">\n  <div class="dashboard-tendency-chart">\n    <div class="dashboard-tendency">\n      ';
 if (name === "asyncRequests") { 
__p+='\n        <div class="dashboard-subtitle-bar">sync</div>\n      <div class="dashboard-figure">\n        <span id="syncPerSecondCurrent"></span>\n        ';
 } else { 
__p+='\n          <div class="dashboard-subtitle-bar">current</div>\n        <div class="dashboard-figure">\n          <span id="'+
((__t=( name ))==null?'':__t)+
'Current"></span>\n          ';
 } 
__p+='\n        </div>\n      </div>\n      <div class="dashboard-tendency">\n        ';
 if (name === "asyncRequests") { 
__p+='\n          <div class="dashboard-subtitle-bar">async</div>\n        <div class="dashboard-figure">\n          <span id="asyncPerSecondCurrent"></span>\n          ';
 } else { 
__p+='\n            <div class="dashboard-subtitle-bar">15-min-avg</div>\n          <div class="dashboard-figure">\n            <span id="'+
((__t=( name ))==null?'':__t)+
'Average"></span>\n            ';
 } 
__p+='\n          </div>\n        </div>\n      </div>\n      <div class="dashboard-sub-bar">'+
((__t=( title ))==null?'':__t)+
'</div>\n    </div>\n    ';
 } 
__p+='\n\n    <ul class="subMenuEntries subViewNavbar">\n      <li id="requests-statistics" class="subMenuEntry active"><a>Statistics</a></li>\n      <li id="system-statistics" class="subMenuEntry "><a>System Resources</a></li>\n      ';
 if (isCluster) { 
__p+='\n        <li id="logs-statistics" class="subMenuEntry "><a>Logs</a></li>\n      ';
 } 
__p+='\n      <li id="metrics-statistics" class="subMenuEntry "><a>Metrics</a></li>\n    </ul>\n\n    <div id="requests">\n      <div class="contentDiv">\n        <div class="dashboard-row pure-u cluster-values" id="node-info"\n             style="width: 100%; margin-top: 0; padding-right: 2px; box-sizing: border-box;">\n        </div>\n\n        ';
 if (hideStatistics !== true) { 
__p+='\n          <div class="dashboard-row pure-u">\n            ';
 largeChart("requestsChart", "Requests per Second") 
__p+='\n\n            ';
 tendency("Request Types", "asyncRequests", false); 
__p+='\n            ';
 tendency("Number of Client Connections", "clientConnections", false); 
__p+='\n          </div>\n\n          <div class="dashboard-row pure-u small-label-padding">\n            ';
 largeChart("dataTransferChart", "Transfer Size per Second") 
__p+='\n            ';
 smallChart("dataTransferDistribution", "Transfer Size per Second (distribution)", false) 
__p+='\n          </div>\n\n          <div class="dashboard-row pure-u small-label-padding">\n            ';
 largeChart("totalTimeChart", "Average Request Time (seconds)") 
__p+='\n            ';
 smallChart("totalTimeDistribution", "Average Request Time (distribution)", false) 
__p+='\n          </div>\n        ';
 } 
__p+='\n      </div>\n    </div>\n\n    <div id="system" class="tendency-box-sizing" style="display: none" ;>\n\n      <div class="dashboard-row pure-u" style="width: 100%;">\n        ';
 tendency("Number of threads", "numberOfThreads", false); 
__p+='\n\n        <div class="dashboard-bar-chart-container pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-3">\n          <div class="dashboard-bar-chart">\n            <div class="dashboard-bar-chart-title">\n              <div class="percentage"></div>\n              <div class="absolut"></div>\n            </div>\n            <div class="dashboard-bar-chart-chart" id="residentSizeChartContainer">\n              <div id="residentSizeChart">\n                <svg></svg>\n              </div>\n            </div>\n          </div>\n          <div class="dashboard-sub-bar">Memory\n          </div>\n        </div>\n\n        ';
 tendency("Virtual Size in GB", "virtualSize", false); 
__p+='\n      </div>\n      <div class="dashboard-row pure-u" style="width: 100%">\n        ';
 mediumChart("pageFaultsChart", "Major Page Faults") 
__p+='\n        ';
 mediumChart("systemUserTimeChart", "Used CPU Time per Second") 
__p+='\n      </div>\n\n    </div>\n\n    <div id="logs" class="tendency-box-sizing" style="display: none">\n      <div id="nodeLogContentView"></div>\n    </div>\n\n    <div id="metrics" class="tendency-box-sizing" style="display: none">\n      <div id="nodeMetricsContentView"></div>\n    </div>\n\n  </div>\n</div>';
}
return __p;
};
