/* jshint browser: true */

/* jshint unused: false */

/* global Backbone, window */
(function () {
  'use strict';

  window.PaginatedCollection = Backbone.Collection.extend({
    page: 0,
    pagesize: 10,
    totalAmount: null,
    getPage: function getPage() {
      return this.page + 1;
    },
    setPage: function setPage(counter) {
      if (counter >= this.getLastPageNumber() && this.totalAmount !== null) {
        this.page = this.getLastPageNumber() - 1;
        return;
      }

      if (counter < 1) {
        this.page = 0;
        return;
      }

      this.page = counter - 1;
    },
    getLastPageNumber: function getLastPageNumber() {
      return Math.max(Math.ceil(this.totalAmount / this.pagesize), 1);
    },
    getOffset: function getOffset() {
      return this.page * this.pagesize;
    },
    getPageSize: function getPageSize() {
      return this.pagesize;
    },
    setPageSize: function setPageSize(newPagesize) {
      if (newPagesize === 'all') {
        this.pagesize = 'all';
      } else {
        try {
          newPagesize = parseInt(newPagesize, 10);
          this.pagesize = newPagesize;
        } catch (ignore) {}
      }
    },
    setToFirst: function setToFirst() {
      this.page = 0;
    },
    setToLast: function setToLast() {
      this.setPage(this.getLastPageNumber());
    },
    setToPrev: function setToPrev() {
      this.setPage(this.getPage() - 1);
    },
    setToNext: function setToNext() {
      this.setPage(this.getPage() + 1);
    },
    setTotal: function setTotal(total) {
      this.totalAmount = total;
    },
    getTotal: function getTotal() {
      return this.totalAmount;
    },
    setTotalMinusOne: function setTotalMinusOne() {
      this.totalAmount--;
    }
  });
})();