/* jshint browser: true */

/* jshint unused: false */

/* global $, Joi, frontendConfig, arangoHelper, _, Backbone, templateEngine, window */
(function () {
  'use strict';

  window.ViewsView = Backbone.View.extend({
    el: '#content',
    readOnly: false,
    template: templateEngine.createTemplate('viewsView.ejs'),
    initialize: function initialize() {},
    refreshRate: 10000,
    sortOptions: {
      desc: false
    },
    searchString: '',
    remove: function remove() {
      this.$el.empty().off();
      /* off to unbind the events */

      this.stopListening();
      this.unbind();
      delete this.el;
      return this;
    },
    events: {
      'click #createView': 'createView',
      'click #viewsToggle': 'toggleSettingsDropdown',
      'click .tile-view': 'gotoView',
      'keyup #viewsSearchInput': 'search',
      'click #viewsSearchSubmit': 'search',
      'click #viewsSortDesc': 'sorting'
    },
    checkVisibility: function checkVisibility() {
      if ($('#viewsDropdown').is(':visible')) {
        this.dropdownVisible = true;
      } else {
        this.dropdownVisible = false;
      }

      arangoHelper.setCheckboxStatus('#viewsDropdown');
    },
    checkIfInProgress: function checkIfInProgress() {
      if (window.location.hash.search('views') > -1) {
        var self = this;

        var callback = function callback(error, lockedViews) {
          if (error) {
            console.log('Could not check locked views');
          } else {
            if (lockedViews.length > 0) {
              _.each(lockedViews, function (foundView) {
                if ($('#' + foundView.collection)) {
                  // found view html container
                  $('#' + foundView.collection + ' .collection-type-icon').removeClass('fa-clone');
                  $('#' + foundView.collection + ' .collection-type-icon').addClass('fa-spinner').addClass('fa-spin');
                } else {
                  $('#' + foundView.collection + ' .collection-type-icon').addClass('fa-clone');
                  $('#' + foundView.collection + ' .collection-type-icon').removeClass('fa-spinner').removeClass('fa-spin');
                }
              });
            } else {
              // if no view found at all, just reset all to default
              $('.tile .collection-type-icon').addClass('fa-clone').removeClass('fa-spinner').removeClass('fa-spin');
            }

            window.setTimeout(function () {
              self.checkIfInProgress();
            }, self.refreshRate);
          }
        };

        if (!frontendConfig.ldapEnabled) {
          window.arangoHelper.syncAndReturnUnfinishedAardvarkJobs('view', callback);
        }
      }
    },
    sorting: function sorting() {
      if ($('#viewsSortDesc').is(':checked')) {
        this.setSortingDesc(true);
      } else {
        this.setSortingDesc(false);
      }

      this.checkVisibility();
      this.render();
    },
    setSortingDesc: function setSortingDesc(yesno) {
      this.sortOptions.desc = yesno;
    },
    search: function search() {
      this.setSearchString(arangoHelper.escapeHtml($('#viewsSearchInput').val()));
      this.render();
    },
    toggleSettingsDropdown: function toggleSettingsDropdown() {
      var self = this; // apply sorting to checkboxes

      $('#viewsSortDesc').attr('checked', this.sortOptions.desc);
      $('#viewsToggle').toggleClass('activated');
      $('#viewsDropdown2').slideToggle(200, function () {
        self.checkVisibility();
      });
    },
    render: function render(data) {
      var self = this;

      if (data) {
        self.$el.html(self.template.render({
          views: self.applySorting(data.result),
          searchString: self.getSearchString()
        }));
      } else {
        this.getViews();
        this.$el.html(this.template.render({
          views: [],
          searchString: self.getSearchString()
        }));
      }

      if (self.dropdownVisible === true) {
        $('#viewsSortDesc').attr('checked', self.sortOptions.desc);
        $('#viewsToggle').addClass('activated');
        $('#viewsDropdown2').show();
      }

      $('#viewsSortDesc').attr('checked', self.sortOptions.desc);
      arangoHelper.setCheckboxStatus('#viewsDropdown');
      var searchInput = $('#viewsSearchInput');
      var strLength = searchInput.val().length;
      searchInput.focus();
      searchInput[0].setSelectionRange(strLength, strLength);
      arangoHelper.checkDatabasePermissions(this.setReadOnly.bind(this));
    },
    setReadOnly: function setReadOnly() {
      this.readOnly = true;
      $('#createView').parent().parent().addClass('disabled');
    },
    setSearchString: function setSearchString(string) {
      this.searchString = string;
    },
    getSearchString: function getSearchString() {
      return this.searchString.toLowerCase();
    },
    applySorting: function applySorting(data) {
      var self = this; // default sorting order

      data = _.sortBy(data, 'name'); // desc sorting order

      if (this.sortOptions.desc) {
        data = data.reverse();
      }

      var toReturn = [];

      if (this.getSearchString() !== '') {
        _.each(data, function (view, key) {
          if (view && view.name) {
            if (view.name.toLowerCase().indexOf(self.getSearchString()) !== -1) {
              toReturn.push(view);
            }
          }
        });
      } else {
        return data;
      }

      return toReturn;
    },
    gotoView: function gotoView(e) {
      var name = $(e.currentTarget).attr('id');

      if (name) {
        var url = 'view/' + encodeURIComponent(name);
        window.App.navigate(url, {
          trigger: true
        });
      }
    },
    getViews: function getViews() {
      var self = this;
      this.collection.fetch({
        success: function success(data) {
          var res = {
            result: []
          };
          self.collection.each(function (view) {
            res.result.push(view.toJSON());
          });
          self.render(res);
          self.checkIfInProgress();
        },
        error: function error(_error) {
          console.log(_error);
        }
      });
    },
    createView: function createView(e) {
      if (!this.readOnly) {
        e.preventDefault();
        this.createViewModal();
      }
    },
    createViewModal: function createViewModal() {
      var buttons = [];
      var tableContent = [];
      tableContent.push(window.modalView.createTextEntry('newName', 'Name', '', false, 'Name', true, [{
        rule: Joi.string().regex(/^[a-zA-Z0-9\-_]*$/),
        msg: 'Only symbols, "_" and "-" are allowed.'
      }, {
        rule: Joi.string().required(),
        msg: 'No view name given.'
      }]));
      tableContent.push(window.modalView.createReadOnlyEntry(undefined, 'Type', 'arangosearch', undefined, undefined, false, undefined));
      buttons.push(window.modalView.createSuccessButton('Create', this.submitCreateView.bind(this)));
      window.modalView.show('modalTable.ejs', 'Create New View', buttons, tableContent);
    },
    submitCreateView: function submitCreateView() {
      var self = this;
      var name = $('#newName').val();
      var options = JSON.stringify({
        name: name,
        type: 'arangosearch',
        properties: {}
      });
      $.ajax({
        type: 'POST',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/view'),
        contentType: 'application/json',
        processData: false,
        data: options,
        success: function success(data) {
          window.modalView.hide();
          arangoHelper.arangoNotification('View', 'Creation in progress. This may take a while.');
          self.getViews();
        },
        error: function error(_error2) {
          if (_error2.responseJSON && _error2.responseJSON.errorMessage) {
            arangoHelper.arangoError('Views', _error2.responseJSON.errorMessage);
          }
        }
      });
    }
  });
})();