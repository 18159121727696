module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="outputEditorWrapper'+
((__t=( counter ))==null?'':__t)+
'" class="outputEditorWrapper">\n    <div class="arangoToolbar arangoToolbarTop">\n      <div class="pull-left">\n        <span class="toolbarType">'+
((__t=(type))==null?'':__t)+
'</span>\n          <span id="spinner"><i class="fa fa-spinner fa-spin"></i><i>Query is operating ...</i></span>\n      </div>\n      <div class="pull-right">\n        <span class="action closeResult" element="outputEditor'+
((__t=( counter ))==null?'':__t)+
'"><i class="fa fa-close" style="display: none"></i></span>\n      </div>\n      <div class="pull-right">\n        <div class="switchAce" counter="'+
((__t=(counter))==null?'':__t)+
'">\n          <span id="json-switch" val="JSON" counter="'+
((__t=(counter))==null?'':__t)+
'">JSON</span>\n          <span id="table-switch" val="Table" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none">Table</span>\n          <span id="graph-switch" val="Graph" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none">Graph</span>\n          <span id="geo-switch" val="Geo" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none">Geo</span>\n        </div>\n      </div>\n    </div>\n    <div id="outputEditor'+
((__t=( counter ))==null?'':__t)+
'" style="opacity: 0.5"></div>\n    <div id="sentWrapper'+
((__t=( counter ))==null?'':__t)+
'" class="sentWrapper" style="display: none">\n      <div class="pull-left">\n        <div id="sentQueryEditor'+
((__t=( counter ))==null?'':__t)+
'"></div>\n      </div>\n      <div class="pull-right">\n        <div id="sentBindParamEditor'+
((__t=( counter ))==null?'':__t)+
'"></div>\n      </div>\n    </div>\n    <div class="arangoToolbar arangoToolbarBottom">\n      <div class="pull-right">\n        ';
 if (type === 'Query') { 
__p+='\n          <button id="copy2gV" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none; margin-right: 0;" class="button-success">Graph Viewer</button>\n          <button id="downloadQueryResult" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none; margin-right: 0;" class="button-success">Download</button>\n          <button id="downloadCsvResult" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none; margin-right: 0;" class="button-success">CSV</button>\n          <button id="copy2aqlEditor" counter="'+
((__t=(counter))==null?'':__t)+
'" style="display: none; margin-right: 8px;" class="button-success">Copy To Editor</button>\n          <button id="cancelCurrentQuery" class="button-danger" style="margin-right: 5px">Cancel</button>\n        ';
 } 
__p+='\n      </div>\n    </div>\n  </div>\n\n  </div>\n';
}
return __p;
};
