module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="headerBar">\n    <div class="search-field">\n      <input type="text" value="'+
((__t=(searchString))==null?'':__t)+
'" id="viewsSearchInput" class="search-input" placeholder="Search..."/>\n      <i id="viewsSearchSubmit" class="fa fa-search"></i>\n    </div>\n    <div class="headerButtonBar">\n      <ul class="headerButtonList">\n        <li class="enabled">\n          <a id="viewsToggle" class="headerButton">\n            <span class="icon_arangodb_settings2" title="Settings"></span>\n          </a>\n        </li>\n      </ul>\n    </div>\n  </div>\n\n  <div id="viewsDropdown2" class="headerDropdown">\n    <div id="viewsDropdown" class="dropdownInner">\n      <ul>\n        <li class="nav-header">Sorting</li>\n        <li><a>\n          <label class="checkbox checkboxLabel">\n            <input class="css-checkbox" type="checkbox" id="viewsSortDesc">\n            <i class="fa"></i>\n            Sort descending\n          </label>\n        </a></li>\n      </ul>\n    </div>\n  </div>\n\n  <div class="contentDiv" id="">\n    <div id="viewsThumbnailsIn" class="tileList pure-u">\n      <div class="tile pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6">\n        <div class="fullBorderBox">\n          <a id="createView" class="add">\n            <span id="newView" class="pull-left add-Icon"><i class="fa fa-plus-circle"></i></span>\n            Add View\n          </a>\n        </div>\n      </div>\n\n\n      ';
 views.forEach(function(view) {
        var viewName = view.name;
      
__p+='\n\n        <div class="tile tile-view pure-u-1-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 pure-u-xl-1-6" id="'+
((__t=(viewName ))==null?'':__t)+
'">\n          <div class="paddingBox">\n            <div class="borderBox"></div>\n            <i class="collection-type-icon fa fa-clone"></i>\n            <div class="tileBadge">\n            ';
 if (view.type) { 
__p+='\n              <span><div class="corneredBadge inProgress">'+
((__t=(view.type))==null?'':__t)+
'</div></span>\n            ';
 } 
__p+='\n            </div>\n            <h5 class="collectionName">'+
((__t=(viewName))==null?'':__t)+
'</h5>\n          </div>\n        </div>\n\n      ';
});
__p+='\n     </div>\n  </div>\n\n';
}
return __p;
};
