module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

let tableStyle = '';
let textStyle = '';
let buttonText = '';

if (activeView === "text") {
  tableStyle = "display: none;";
  buttonText = "Show Table";
} else {
  textStyle = "display: none;";
  buttonText = "Show Text";
}

__p+='\n\n<div id="metricContent" style="padding-top: 0;">\n\n  <div class="bottomButtonBar">\n    <button id="downloadAs" class="pull-right button-success">Download</button>\n    <button id="reloadMetrics" class="pull-right button-success">Reload</button>\n    <button id="toggleView" class="pull-right button-close">'+
((__t=( buttonText ))==null?'':__t)+
'</button>\n  </div>\n\n  <div id="metricsAsTable" style="'+
((__t=( tableStyle ))==null?'':__t)+
'">\n    <div class="header first">Counters</div>\n    <div class="pure-g" style="background: white; padding-top: 10px;">\n      <div id="'+
((__t=( name ))==null?'':__t)+
'" class="metricsBox pure-u-1-1">\n        <table class="pure-table pure-table-metrics-counter">\n          <thead>\n          <tr>\n            <th>Name</th>\n            <th>Value</th>\n            <th>Info</th>\n          </tr>\n          </thead>\n\n          <tbody>\n          ';
 _.each(collection.where({type: "COUNTER"}), (model) => { 
            _.each(model.get('metrics'), (metric) => {
              let l = [];
              _.each(Object.keys(metric.labels || {}), (label) => {
                if (label === 'role' || label === 'shortname') {
                  return;
                }
                l.push(label + '=' + JSON.stringify(metric.labels[label]));
              });
              let labelString = ' {' + l.join(', ') + '}'; 
              if (labelString.length <= 3) {
                labelString = '';
              }
          
__p+='\n            <tr>\n              <td>'+
((__t=( model.get('name') + labelString ))==null?'':__t)+
'</td>\n              <td class="alignRight">'+
((__t=( metric.value ))==null?'':__t)+
'</td>\n              <td>'+
((__t=( model.get('info') ))==null?'':__t)+
'</td>\n            </tr>\n          ';
 }); 
__p+='\n          ';
 }); 
__p+='\n          </tbody>\n\n          <tbody>\n          <tr>\n            <td style="border-right: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\n              <div class="header">Gauges</div>\n            </td>\n            <td style="border-left: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\n              <div class="header"></div>\n            </td>\n            <td style="border-left: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\n              <div class="header"></div>\n            </td>\n          </tr>\n          </tbody>\n\n          <tbody>\n          ';
 _.each(collection.where({type: "GAUGE"}), (model) => { 
            _.each(model.get('metrics'), (metric) => {
              let l = [];
              _.each(Object.keys(metric.labels || {}), (label) => {
                if (label === 'role' || label === 'shortname') {
                  return;
                }
                l.push(label + '=' + JSON.stringify(metric.labels[label]));
              });
              let labelString = ' {' + l.join(', ') + '}'; 
              if (labelString.length <= 3) {
                labelString = '';
              }
          
__p+='\n            <tr>\n              <td>'+
((__t=( model.get('name')+ labelString  ))==null?'':__t)+
'</td>\n              <td class="alignRight">'+
((__t=( metric.value ))==null?'':__t)+
'</td>\n              <td>'+
((__t=( model.get('info') ))==null?'':__t)+
'</td>\n            </tr>\n          ';
 }); 
__p+='\n          ';
 }); 
__p+='\n          </tbody>\n\n        </table>\n      </div>\n    </div>\n\n    <div class="header child">Histograms</div>\n    <div class="pure-g" style="background: white; padding-top: 10px;">\n      ';
 _.each(collection.where({type: "HISTOGRAM"}), (model) => { 
        _.each(model.get('metrics'), (metric) => {
          let l = [];
          _.each(Object.keys(metric.labels || {}), (label) => {
            if (label === 'role' || label === 'shortname') {
              return;
            }
            l.push(label + '=' + JSON.stringify(metric.labels[label]));
          });
          let labelString = ' {' + l.join(', ') + '}'; 
          if (labelString.length <= 3) {
            labelString = '';
          }
      
__p+='\n        <div id="'+
((__t=( name ))==null?'':__t)+
'" class="metricsBox pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-3">\n          ';

            let count = metric.count;
            if (!metric.hasOwnProperty('count')) {
              /* use value from highest bucket */
              count = 0;
              _.forEach(metric.buckets, (value, key) => {
                try {
                  count = Number.parseFloat(value);
                } catch (e) {
                  count = 'n/A';
                }
              });
            }
          
__p+='\n          <div class="tableHeader">'+
((__t=( model.get("name") + labelString ))==null?'':__t)+
' ('+
((__t=( count ))==null?'':__t)+
')\n            <div\n              style="font-weight: 100">'+
((__t=( model.get("info") ))==null?'':__t)+
'</div>\n          </div>\n          <table class="pure-table pure-table-metrics-histogram">\n            <thead>\n            <tr>\n              <th>Bucket</th>\n              <th>Value</th>\n            </tr>\n            </thead>\n            <tbody>\n\n            ';
 _.forEach(metric.buckets, (value, key) => { 
__p+='\n              <tr>\n                <td class="alignRight">&lt;= '+
((__t=( key ))==null?'':__t)+
'</td>\n                <td class="alignRight">'+
((__t=( value ))==null?'':__t)+
'</td>\n              </tr>\n            ';
 }); 
__p+='\n\n            </tbody>\n          </table>\n        </div>\n      ';
 }); 
__p+='\n      ';
 }); 
__p+='\n\n    </div>\n  </div>\n\n  <div id="metricsAsText" style="'+
((__t=( textStyle ))==null?'':__t)+
'">\n    <pre>'+
((__t=( collection.metricsAsText() ))==null?'':__t)+
'</pre>\n  </div>\n\n</div>\n';
}
return __p;
};
