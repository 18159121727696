module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
var appInfos = attributes.app.split(":"); 
__p+='\n<div id="change-foxx" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display:none">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n    <a class="arangoHeader">Modify Service</a>\n  </div>\n  <div class="modal-body">\n    <table>\n      <tr>\n        <th class="collectionTh">Name:</th>\n        <th class="collectionTh"><strong>'+
((__t=(appInfos[1] ))==null?'':__t)+
'</strong></th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Documentation:</th>\n        <th class="collectionTh">'+
((__t=(documentationJsonUrl))==null?'':__t)+
'</th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Mount:</th>\n        <th class="collectionTh"><input type="text" id="change-mount-point" name="mountpoint" value="'+
((__t=(attributes.mount))==null?'':__t)+
'"/></th>\n        <th><span class="modalTooltips arangoicon icon_arangodb_info" title="The path where the app can be reached."></span></th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Version:</th>\n        <th class="collectionTh">\n          <select>\n            <option selected>'+
((__t=(appInfos[2] ))==null?'':__t)+
'</option>\n          </select>\n        <th>\n      </tr>\n      <tr>\n        <th class="collectionTh">System:</th>\n        <th class="collectionTh">\n          '+
((__t=(attributes.isSystem ? "Yes" : "No" ))==null?'':__t)+
'\n        <th>\n      </tr>\n      <tr>\n        <th class="collectionTh">Status:</th>\n        <th class="collectionTh">\n          ';
if (attributes.active) {
__p+='\n            <div class="modal-text active">\n              Active\n            </div>\n          ';
} else {
__p+='\n            <div class="modal-text inactive">\n              Inactive\n            </div>\n          ';
}
__p+='\n        </th>\n      </tr>\n    </table>\n  </div>\n  <div id="colFooter" class="modal-footer">\n    <button id="uninstall" class="button-danger"'+
((__t=((attributes.isSystem || attributes.development) ? " disabled" : ""))==null?'':__t)+
'>Uninstall</button>\n    <button id="change" class="button-success pull-right">Save</button>\n    ';
if (false && attributes.active) {
__p+='\n      <button id="deactivate" class="button-warning pull-right" style="margin-right:8px" disabled>Deactivate</button>\n    ';
} else if (false) {
__p+='\n      <button id="activate" class="button-success pull-right" style="margin-right:8px" disabled>Activate</button>\n    ';
}
__p+='\n    \n  </div>\n</div>\n';
}
return __p;
};
