module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div id="modal-dialog" class="modal hide fade createModalDialog" tabindex="-1" role="dialog"\n       aria-labelledby="myModalLabel" aria-hidden="true">\n   ';
 if (title !== null) { 
__p+='\n   <div class="modal-header">\n      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\n      <a class="arangoHeader">'+
((__t=(title))==null?'':__t)+
'</a>\n   </div>\n   ';
}
__p+='\n\n    ';
 if (tabBar) { 
__p+='\n      <div class="modal-tabbar">\n        <ul id="infoTab" class="nav nav-tabs">\n          ';
 var counter = 0; 
__p+='\n          ';
 _.each(tabBar, function(value) { 
__p+='\n            ';
 if (counter === 0) { 
__p+='\n              <li class="active"><a href="#'+
((__t=(value))==null?'':__t)+
'" data-toggle="tab">'+
((__t=(value))==null?'':__t)+
'</a></li>\n            ';
} else {
__p+='\n              <li><a href="#'+
((__t=(value))==null?'':__t)+
'" data-toggle="tab">'+
((__t=(value))==null?'':__t)+
'</a></li>\n            ';
 } 
__p+='\n            ';
 counter++; 
__p+='\n          ';
 }); 
__p+='\n        </ul>\n      </div>\n    ';
}
__p+='\n\n    <div class="modal-body">\n\n      ';
 if (tabBar) { 
__p+='\n        <div class="tab-content">\n          ';
 var counter = 0; 
__p+='\n          ';
 _.each(tabBar, function(value) { 
__p+='\n            ';
 if (counter === 0) { 
__p+='\n              <div class="tab-pane tab-pane-modal active" id="'+
((__t=(value))==null?'':__t)+
'">\n              </div>\n            ';
} else {
__p+='\n              <div class="tab-pane tab-pane-modal" id="'+
((__t=(value))==null?'':__t)+
'">\n              </div>\n            ';
 } 
__p+='\n            ';
 counter++; 
__p+='\n          ';
 }); 
__p+='\n        </div>\n      ';
}
__p+='\n\n    </div>\n\n    ';
 if (!hideFooter) { 
__p+='\n    <div class="modal-footer">\n      ';

      _.each(buttons, function(value, key){
        var type = value.type,
          title = value.title,
          disabled = '';
          if (value.disabled) {
            disabled = 'disabled';
          }
        
__p+='\n      <button id="modalButton'+
((__t=(key))==null?'':__t)+
'" class="button-'+
((__t=(type))==null?'':__t)+
'" '+
((__t=(disabled))==null?'':__t)+
'>'+
((__t=(title))==null?'':__t)+
'</button>\n      ';
});
__p+='\n    </div>\n    ';
 } 
__p+='\n    <div class="alert alert-error modal-delete-confirmation" id="modal-delete-confirmation">\n      <strong>'+
((__t=(confirm||'Really delete?'))==null?'':__t)+
'</strong>\n      <button id="modal-confirm-delete" class="button-danger pull-right modal-confirm-delete">Yes</button>\n      <button id="modal-abort-delete" class="button-neutral pull-right">No</button>\n    </div>\n  </div>\n';
}
return __p;
};
