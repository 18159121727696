module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <tr class="tableRow" id="row_newEdgeDefinitions'+
((__t=( number))==null?'':__t)+
'">\n    <th class="collectionTh">Edge definitions*:</th>\n    <th class="collectionTh">\n      <input type="hidden" id="newEdgeDefinitions'+
((__t=( number))==null?'':__t)+
'" value="" placeholder="Edge definitions" tabindex="-1" class="select2-offscreen">\n      <button id="remove_newEdgeDefinitions'+
((__t=( number))==null?'':__t)+
'" class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete"></button>\n    </th><th>\n    <span class="modalTooltips arangoicon icon_arangodb_info" title="An edge definition defines a relation of the graph">\n    </span>\n    </th>\n  </tr>\n  <tr class="tableRow" id="row_fromCollections'+
((__t=( number))==null?'':__t)+
'">\n    <th class="collectionTh">fromCollections*:</th>\n    <th class="collectionTh">\n      <input type="hidden" id="fromCollections'+
((__t=( number))==null?'':__t)+
'" value="" placeholder="fromCollections" tabindex="-1" class="select2-offscreen">\n    </th><th>\n    <span class="modalTooltips arangoicon icon_arangodb_info" title="The collection that contain the start vertices of the relation.">\n    </span>\n  </th>\n  </tr>\n  <tr class="tableRow" id="row_toCollections'+
((__t=( number))==null?'':__t)+
'">\n    <th class="collectionTh">toCollections*:</th>\n    <th class="collectionTh">\n      <input type="hidden" id="toCollections'+
((__t=( number))==null?'':__t)+
'" value="" placeholder="toCollections" tabindex="-1" class="select2-offscreen">\n    </th><th>\n    <span class="modalTooltips arangoicon icon_arangodb_info" title="The collection that contain the end vertices of the relation.">\n    </span>\n  </th>\n  </tr>\n';
}
return __p;
};
