module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div class="arangoToolbar arangoToolbarTop arangoFoxxToolbarTop">\n    <div class="pull-left">\n    </div>\n\n    <div class="pull-right">\n    </div>\n  </div>\n\n  <div class="contentDiv createModalDialog">\n    <div id="urlFoxx" class="foxxesInner modal-body" style="min-height: 150px;">\n        <div>\n          <p>\n            Download a Foxx service from a public available url. Access using credentials in the URL is allowed (https://username:password@www.example.com/). \n          </p>\n          <table>\n            <tr class="tableRow">\n              <th class="collectionInfoTh">\n                URL*:\n              </th>\n              <th class="collectionInfoTh">\n                <input type="text" id="repository" value="" placeholder="https://www.example.com/foxx.zip">\n              </th>\n            </tr>\n          </table>\n        </div>\n    </div>\n    <div class="modal-footer">\n      ';
 if (upgrade) { 
__p+='\n        <button id="installUrlService" class="button-success" style="float:right" disabled>Replace</button>\n      ';
 } else { 
__p+='\n        <button id="installUrlService" class="button-success" style="float:right" disabled>Install</button>\n      ';
 } 
__p+='\n    </div>\n  </div>\n\n';
}
return __p;
};
