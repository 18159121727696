module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div class="innerContent">\n    <div id="viewDocumentation" class="infoBox" style="margin-top: 10px; margin-bottom: 30px;">\n      <h4>Info</h4>\n      <p>See the <a style="color: #5bc0de" href="https://www.arangodb.com/docs/stable/arangosearch-views.html" target="_blank"><b>ArangoSearch Views documentation</b></a> to learn how to configure Views.</p>\n    </div>\n    <div id="viewProcessing"class="infoBox" style="margin-top: 10px; margin-bottom: 30px; display: none">\n      <h4>Processing <i class="fa fa-spinner fa-spin"></i></h4>\n      <p>The view is currently in processing. No editing possible right now.</p>\n    </div>\n    <div id="propertiesEditor" class="document-editor" style="border-top: 1px solid rgba(140,138,137,.25)"></div>\n    <div class="bottomButtonBar">\n      <button id="savePropertiesButton" class="button-close pull-right" disabled>Save</button>\n      ';
 if (!window.frontendConfig.isCluster) { 
__p+='\n        <button id="renameViewButton" class="button-warning pull-right">Rename</button>\n      ';
 } 
__p+='\n      <button id="deleteViewButton" class="button-danger pull-right">Delete</button>\n      <span class="buttonBarInfo pull-right"></span>\n    </div>\n  </div>\n  \n';
}
return __p;
};
