module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div id="applierContent" class="innerContent applierContent replicationContent">\n    <div class="repl-applier">\n\n      ';
 _.each(data, function (properties, name) { 
__p+='\n\n        <div class="categoryBar">\n          <h4 style="text-transform: capitalize;">'+
((__t=(name))==null?'':__t)+
'</h4>\n        </div>\n\n          <table id="applier-'+
((__t=(name))==null?'':__t)+
'" class="pure-table">\n            <tbody>\n              ';
 if (typeof properties === 'string') { 
__p+='\n                <tr>\n                  <td>'+
((__t=(name))==null?'':__t)+
'</td>\n                  <td>'+
((__t=(properties))==null?'':__t)+
'</td>\n                </tr>\n              ';
 } else { 
__p+='\n                ';
 _.each(properties, function (val, key) { 
__p+='\n                  <tr>\n                    <td>'+
((__t=(key))==null?'':__t)+
'</td>\n\n                    <td>\n                      ';
 if (typeof val !== 'string' && typeof val !== 'number' && typeof val !== 'boolean') { 
__p+='\n                        <table>\n                          <tbody>\n                            ';
 _.each(val, function (v, k) { 
__p+='\n                              <tr>\n                                <td>'+
((__t=(k))==null?'':__t)+
'</td>\n                                <td>'+
((__t=(v))==null?'':__t)+
'</td>\n                              </tr>\n                            ';
 }); 
__p+='\n                          <tbody>\n                        </table>\n                      ';
 } else {
__p+='\n                        '+
((__t=(val))==null?'':__t)+
'\n                      ';
 } 
__p+='\n                    </td>\n\n                  </tr>\n                ';
 }); 
__p+='\n              ';
 } 
__p+='\n            </tbody>\n          </table>\n\n      ';
 }); 
__p+='\n\n    </div>\n  </div>\n';
}
return __p;
};
