module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

  var n,v,db;
  if (name) {
    n = name || "";
    v = version || "";
  }

__p+='\n<div class="footer-left">\n<!--\n  <div class="social-icons">\n    <p><a href="https://twitter.com/arangodb" target="_blank"><i class="fa fa-twitter"></i></a></p>\n    <p><a href="https://www.arangodb.com/community" target="_blank"></a><i class="fa fa-envelope"></i></a></p>\n    <p><a href="https://stackoverflow.com/questions/tagged/arangodb" target="_blank"></a><i class="fa fa-stack-overflow"></i></a></p>\n    <p><a href="https://groups.google.com/group/arangodb" target="_blank"></a><i class="fa fa-google"></i></a></p>\n  </div>\n-->\n</div>\n\n<div class="footer-center">\n  <p>Help <i class="fa fa-keyboard-o"></i></p>\n</div>\n\n<div class="footer-right">\n  <p>';
 if(n) { 
__p+='<a>'+
((__t=(n))==null?'':__t)+
' '+
((__t=(v))==null?'':__t)+
' ';
 } 
__p+='</a></p>\n</div>\n';
}
return __p;
};
