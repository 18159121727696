module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="paddingBox">\n    <div class="foxxDesc">\n      <p><span class="foxxName">'+
((__t=(model.name))==null?'':__t)+
'</span></p>\n      <p><span class="foxxCategory">'+
((__t=(model.categories))==null?'':__t)+
'</span></p>\n      <!--<p><i class="fa fa-star"></i><span class="foxxStars">23</span></p>-->\n    </div>\n    <div class="foxxActions">\n      ';
 if (upgrade) { 
__p+='\n        <button class="installFoxx button-success" appId="'+
((__t=(model.name ))==null?'':__t)+
'" appVersion="'+
((__t=(model.latestVersion))==null?'':__t)+
'" >Replace</button>\n      ';
 } else { 
__p+='\n        <button class="installFoxx button-success" appId="'+
((__t=(model.name ))==null?'':__t)+
'" appVersion="'+
((__t=(model.latestVersion))==null?'':__t)+
'" >Install</button>\n      ';
 } 
__p+='\n    </div>\n    <div class="borderBox"></div>\n    <img src="'+
((__t=( thumbnail ))==null?'':__t)+
'" height="50" width="50" alt="Icon for Service" class="icon">\n  </div>\n';
}
return __p;
};
