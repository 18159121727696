!function (a) {
  "use strict";

  function n(a) {}

  function e(a) {
    a.stopPropagation();
  }

  a.fn.pagination = function (i) {
    function t(n, i) {
      var t = i >= 1 && i <= l.lastPage,
          s = a("<a/>").append(n),
          r = a("<li/>").append(s);
      return t ? (s.click(function () {
        l.click(i);
      }), a(".arango-pagination").next().children().first().removeClass("disabledPag"), a(".arango-pagination").prev().children().first().removeClass("disabledPag")) : (r.addClass("disabledPag"), setTimeout(function () {
        a(r).find("i").hasClass("fa-angle-right") ? a(".arango-pagination").next().children().first().addClass("disabledPag") : a(r).find("i").hasClass("fa-angle-left") && a(".arango-pagination").prev().children().first().addClass("disabledPag");
      }, 50)), s.click(e), i === l.page && r.addClass("active active-arango-pagination-button"), r;
    }

    function s() {
      var n,
          e = a('<ul class="arango-pagination"/>');

      for (e.append(t(l.prev, l.page - 1)), n = g; p >= n; n += 1) {
        e.append(t(n, n));
      }

      return e.append(t(l.next, l.page + 1));
    }

    var l = a.extend({
      prev: '<span><i class="fa fa-angle-left"/></span>',
      next: '<span><i class="fa fa-angle-right"/></span>',
      left: 3,
      right: 3,
      page: 1,
      lastPage: 1,
      click: n
    }, i || {}),
        r = l.left + l.right,
        g = Math.max(1, l.page - l.left),
        p = g + r;
    return l.lastPage < p && (g = Math.max(1, l.lastPage - r), p = l.lastPage), this.each(function (n) {
      var e = a(this),
          i = e.children("ul");
      i.length ? i.replaceWith(s()) : e.append(s());
    });
  };
}(jQuery);