module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div id="scaleContent" class="innerContent">\n\n  ';
 var genClass = "pure-u-1-5";
__p+='\n\n    <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title">\n      <div class="pure-table-row">\n        <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">Type</div>\n        <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Running</div>\n        <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Planned</div>\n        <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Status</div>\n        <div class="'+
((__t=( genClass ))==null?'':__t)+
' right"></div>\n      </div>\n    </div>\n\n    <div class="pure-g cluster-nodes pure-table pure-table-body">\n\n        <div class="pure-table-row noHover">\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">Coordinators</div>\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="runningCoords">'+
((__t=( runningCoords ))==null?'':__t)+
'</div>\n          ';
 if (initialized === true) { 
__p+='\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="plannedCoords"></div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="statusCoords"></div>\n          ';
 } else { 
__p+='\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="plannedCoords"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="statusCoords"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n          ';
 } 
__p+='\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' actions right">\n            <i class="fa fa-minus-circle" aria-hidden="true" id="removeCoord"></i>\n            <i class="fa fa-plus-circle" aria-hidden="true" id="addCoord"></i>\n          </div>\n        </div>\n\n        <div class="pure-table-row noHover">\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">DBServers</div>\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="runningDBs">'+
((__t=( runningDBs ))==null?'':__t)+
'</div>\n          ';
 if (initialized === true) { 
__p+='\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="plannedDBs"></div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="statusDBs"></div>\n          ';
 } else { 
__p+='\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="plannedDBs"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid" id="statusDBs"><i class="fa fa-circle-o-notch fa-spin"></i></div>\n          ';
 } 
__p+='\n          <div class="'+
((__t=( genClass ))==null?'':__t)+
' actions right">\n            <i class="fa fa-minus-circle" aria-hidden="true" id="removeDBs"></i>\n            <i class="fa fa-plus-circle" aria-hidden="true" id="addDBs"></i>\n          </div>\n        </div>\n\n    </div>\n\n  </div>\n\n';
}
return __p;
};
