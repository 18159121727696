/* jshint browser: true */

/* global window, arangoHelper, Backbone, $, window, _ */
(function () {
  'use strict';

  window.ArangoDatabase = Backbone.Collection.extend({
    model: window.DatabaseModel,
    sortOptions: {
      desc: false
    },
    url: arangoHelper.databaseUrl('/_api/database/user'),
    comparator: function comparator(item, item2) {
      var a = item.get('name').toLowerCase();
      var b = item2.get('name').toLowerCase();

      if (this.sortOptions.desc === true) {
        return a < b ? 1 : a > b ? -1 : 0;
      }

      return a > b ? 1 : a < b ? -1 : 0;
    },
    parse: function parse(response) {
      if (!response) {
        return;
      }

      return _.map(response.result, function (v) {
        return {
          name: v
        };
      });
    },
    initialize: function initialize() {
      var self = this;
      this.fetch({
        cache: false
      }).done(function () {
        self.sort();
      });
    },
    setSortingDesc: function setSortingDesc(yesno) {
      this.sortOptions.desc = yesno;
    },
    getDatabases: function getDatabases() {
      var self = this;
      this.fetch({
        cache: false
      }).done(function () {
        self.sort();
      });
      return this.models;
    },
    getDatabasesForUser: function getDatabasesForUser(callback) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: this.url,
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          callback(false, data.result.sort());
        },
        error: function error() {
          callback(true, []);
        }
      });
    },
    createDatabaseURL: function createDatabaseURL(name, protocol, port) {
      var loc = window.location;
      var hash = window.location.hash;

      if (protocol) {
        if (protocol === 'SSL' || protocol === 'https:') {
          protocol = 'https:';
        } else {
          protocol = 'http:';
        }
      } else {
        protocol = loc.protocol;
      }

      port = port || loc.port;
      var url = protocol + '//' + window.location.hostname + ':' + port + '/_db/' + encodeURIComponent(name) + '/_admin/aardvark/standalone.html';

      if (hash) {
        var base = hash.split('/')[0];

        if (base.indexOf('#collection') === 0) {
          base = '#collections';
        }

        if (base.indexOf('#service') === 0) {
          base = '#services';
        }

        url += base;
      }

      return url;
    },
    getCurrentDatabase: function getCurrentDatabase(callback) {
      $.ajax({
        type: 'GET',
        cache: false,
        url: arangoHelper.databaseUrl('/_api/database/current'),
        contentType: 'application/json',
        processData: false,
        success: function success(data) {
          if (data.code === 200) {
            callback(false, data.result.name);
          } else {
            callback(false, data);
          }
        },
        error: function error(data) {
          callback(true, data);
        }
      });
    },
    hasSystemAccess: function hasSystemAccess(callback) {
      var callback2 = function callback2(error, list) {
        if (error) {
          arangoHelper.arangoError('DB', 'Could not fetch databases');
        } else {
          callback(false, _.includes(list, '_system'));
        }
      };

      this.getDatabasesForUser(callback2);
    }
  });
})();