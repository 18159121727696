module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <div class="arangoToolbar arangoToolbarTop arangoFoxxToolbarTop">\n    <div class="pull-left">\n    </div>\n\n    <div class="pull-right">\n    </div>\n  </div>\n\n  <div class="contentDiv createModalDialog">\n    <div id="uploadFoxxes" class="foxxesInner modal-body">\n        <div>\n          <p>\n  Upload a Foxx service bundle. The Foxx service bundle should be a zip archive containing all service files as a directory structure, including the manifest and any required <code style="padding: 0px 2px; font-weight: 10pt;">node_modules</code> dependencies. If your service doesn\'t have any dependencies, configuration or scripts you can also upload a single JavaScript file that will act as the service\'s entry point or "main" file.\n          </p>\n          <table>\n            <tr class="tableRow" style="display: none">\n              <th class="collectionInfoTh">\n                Enable Legacy Mode:\n              </th>\n              <th class="collectionInfoTh">\n                <input type="checkbox" id="zip-app-islegacy" value="true">\n              </th>\n            <th>\n              <span class="modalTooltips arangoicon icon_arangodb_info" title="Legacy Compatibility Mode allows mounting some Foxx services written for ArangoDB 2.8 or older. This overrides the ArangoDB version specified in the service manifest. See the Foxx documentation for more information on running legacy services in ArangoDB 3.">\n              </span>\n            </th>\n            </tr>\n          </table>\n        </div>\n        <div id="upload-foxx-zip">Upload File</div>\n      </div>\n      <div class="modal-footer">\n        ';
 if (upgrade) { 
__p+='\n          <button id="installUploadService" class="button-success" style="float:right" disabled>Replace</button>\n        ';
 } else { 
__p+='\n          <button id="installUploadService" class="button-success" style="float:right" disabled>Install</button>\n        ';
 } 
__p+='\n      </div>\n    </div>\n  </div>\n';
}
return __p;
};
